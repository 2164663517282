<template>
  <div>
    <v-card outlined height="320px" class="pa-0 my-4">
      <v-card-text>
        <v-row v-if="dishList">
          <v-col cols="8">
            <search-product
              :dishList="dishList"
              @filterProduct="filterProduct"
              selectable
              @onSelectAll="onSelectAll"
            />
          </v-col>
        </v-row>
        <v-row v-if="dishList">
          <v-col cols="12">
            <div>
              <v-slide-group
                multiple
                show-arrows
                :prev-icon="'mdi-arrow-left-drop-circle'"
                :next-icon="'mdi-arrow-right-drop-circle'"
              >
                <v-slide-item v-for="(product, key) in filteredList" :key="key">
                  <v-card class="ma-2 pa-1" height="95" width="130">
                    <v-checkbox
                      class="pa-0 ma-0"
                      dense
                      v-model="selectedProductIds"
                      label=""
                      :value="product.id"
                    ></v-checkbox>

                    {{ getProductName(product) }}
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="dishList" class="mt-8">
          <v-col cols="12">
            <v-btn
              small
              dark
              color="info"
              :disabled="selectedProductIds.length == 0"
              :href="convertData"
              :download="`${filename}.json`"
            >
              Export {{ selectedProductIds.length }} Products<v-icon right
                >get_app</v-icon
              >
            </v-btn>

            <span v-if="selectedProductIds.length > 0" class="caption ml-2">
              Once you download {{ filename }}.json, please login with different
              account and import.</span
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <import-products :uid="uid" :userProfile="userProfile" />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { mapGetters } from 'vuex'
import moment from 'moment'
import SearchProduct from './SearchProduct'
import ImportProducts from './ImportProducts'
export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    userProfile: {
      type: Object,
      required: true
    }
  },
  components: {
    FileUpload,
    SearchProduct,
    ImportProducts
  },
  data() {
    return {
      selectAll: false,
      filteredList: [],
      food_menu_filter: [],
      non_empty_menu: [],
      selectedProductIds: [],
      selectedProducts: []
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      dishList: 'dishList',
      menus: 'menus'
    }),
    filename() {
      return moment().format('MM_DD_YYYY') + '_product_data'
    },
    convertData() {
      let dData = JSON.stringify(this.selectedProducts, null, 2)

      let blob = new Blob([dData], { type: 'application/json' })

      return window.URL.createObjectURL(blob)
    }
  },
  watch: {
    selectedProductIds(nv) {
      if (nv.length) {
        this.selectedProducts = this.filteredList.filter(e => nv.includes(e.id))
      }
    }
  },
  async mounted() {
    this.selectedProducts = []

    console.log(`Loading products of ${this.uid}`)

    await this.$store.dispatch('loadDishList', { uid: this.uid })

    this.$store.dispatch('loadMenus', { uid: this.uid }).then(list => {
      let sub_menus = list ? list.filter(menu => menu.type != 'parent') : []
      if (sub_menus && sub_menus.length > 0 && this.dishList.length > 10) {
        // if there is at least one catgeory and there are more than 10 products
        this.show_all_products = false
      } else {
        this.show_all_products = true
      }
    })
    this.filteredList = this.dishList
    // filters through empty menus and adds to array for combobox
    let list = []

    if (this.menus) {
      this.menus.forEach(menu => {
        if (menu.items) {
          list.push(menu)
        }
      })
      this.non_empty_menu = list
    }
  },
  methods: {
    filterProduct(productList) {
      this.selectedProductIds = []
      this.filteredList = productList
    },
    onSelectAll(selectAllProducts) {
      this.selectAll = selectAllProducts
      if (this.selectAll) {
        this.selectedProducts = this.filteredList
        this.selectedProductIds = this.selectedProducts.map(e => e.id)
      } else {
        this.selectedProducts = []
        this.selectedProductIds = []
      }
    },
    getProductImage(product) {
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : ''
    },
    getProductName(product) {
      return product ? product.name : ''
    }
  }
}
</script>
