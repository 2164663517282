<template>
  <div>
    <v-card outlined width="100%" height="200px" class="my-2">
      <v-card-title>Import Products</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <file-upload
              class="v-btn v-btn__content "
              extensions="json"
              accept="application/json"
              v-model="files"
              input-id="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              ref="upload"
            >
              <i class="fa fa-plus"></i>
              Select file
            </file-upload>
          </v-col>

          <v-col cols="4" v-if="files && files.length > 0">
            Found <b>{{ importCount }}</b> products to import
            <v-checkbox
              v-model="merge"
              label="Merge Exisitng Data"
            ></v-checkbox>

            <v-btn color="success" small @click.prevent="upload">Upload</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    userProfile: {
      type: Object,
      required: true
    }
  },
  components: {
    FileUpload
  },
  data() {
    return {
      merge: false,
      importCount: 0,
      data: [],
      files: []
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
    filename() {
      return moment().format('MM_DD_YYYY') + '_product_data'
    }
  },
  methods: {
    inputFile(newFile, oldFile) {
      if (this.files.length < 1) {
        return
      }
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
      const fr = new FileReader()
      fr.readAsText(this.files[0].file)

      fr.onload = evt => {
        const data = JSON.parse(evt.target.result)
        if (data && data.length > 0) {
          this.importCount = data.length

          this.merge = data[0].uid === this.uid
        }
      }
    },
    inputFilter(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },

    upload() {
      this.uploadProgress = true
      const fr = new FileReader()
      fr.readAsText(this.files[0].file)
      const store = this.$store
      fr.onload = evt => {
        store
          .dispatch('importProducts', {
            data: JSON.parse(evt.target.result),
            uid: this.uid,
            merge: this.merge,
            profile: this.userProfile
          })
          .then(() => {
            this.files = []

            this.$store
              .dispatch('setMessage', {
                color: 'info',
                title: 'Imported',
                body: 'products are imported.'
              })
              .then(() => {
                if (this.userProfile.status !== 'approved') {
                  this.$router.push(
                    '/profile/wizard/menus/products_sub/list_products'
                  )
                }
              })
          })
      }
    }
  }
}
</script>
